.apiCallsContainer {

    .apiCallsHeader {
        margin-bottom: 10px;
    }

    .apiCallsContent {
        display: grid;
        font-size: 12px;
        grid-template-columns: 200px auto;
        grid-gap: 15px;
        margin-bottom: 15px;

        &.smallLayout {
            grid-template-columns: auto;
        }

        .apiCallsTabGroup {
            margin-bottom: 15px;

            .tabGroupHeader {
                font-size: 16px;
                margin-bottom: 5px;
            }

            .actionsList {
                background-color: #fff;

                div {
                    cursor: pointer;
                    padding: 8px 10px;

                    &:hover, &.selected {
                        background-color: #ddd;
                    }
                }
            }
        }

        .apiCallsActionContent {
            background-color: #fff;
            // border-radius: 10px;
            padding: 8px 10px;

            .header {
                font-size: 18px;
                padding: 5px 5px 10px;
            }

            .apiContainer {
                .apiCall {
                    display: grid;
                    grid-gap: 15px;
                    grid-template-columns: 1fr 1fr;

                    &.smallLayout {
                        grid-template-columns: auto;
                    }
                }
            }
        }
    }
}
