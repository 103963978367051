.tests {

    .testsHeader {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 10px;

        .lastUpdatedContainer {
            text-align: right;
        }
    }

    .myTestSectionHeader {
        font-size: 16px;
        margin: 15px 0px 10px;
    }

    .winningTest .tableCell {
        background-color: rgba(231, 255, 231) !important;
    }

    .losingTest .tableCell {
        background-color: rgb(255 231 231) !important;
    }

    .myTestChartContainer {
        background-color: #fff;
        border-radius: 15px;
        min-height: 100px;
        padding: 8px 10px;

        .chartSelectBox {
            width: 300px;
        }

        .tradeResultsChartContainer {
            height: 500px;
        }
    }
}