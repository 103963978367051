.eTradeLogin {
    color: #000;
    display: grid;
    // grid-gap: 0px 20px;
    grid-template-columns: auto 50% auto;
    height: 100%;
    position: fixed;
    width: 100%;

    .darkMode & {
        color: #ddd;
    }
}