.emergencyContainer {

    .emergencyHeader {
        margin-bottom: 10px;
    }

    .emergencyContent {
        display: grid;
        font-size: 12px;
        grid-template-columns: 200px auto;
        grid-gap: 15px;
        margin-bottom: 15px;

        .emergencyActionsList {
            background-color: #fff;

            div {
                cursor: pointer;
                padding: 8px 10px;

                &:hover, &.selected {
                    background-color: #ddd;
                }
            }
        }

        .emergencyActionContent {
            background-color: #fff;
            // border-radius: 10px;
            padding: 8px 10px;

            .header {
                font-size: 18px;
                padding: 5px 5px 10px;
            }
        }
    }
}