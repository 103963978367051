.optionChainsContainer {
    display: grid;
    grid-gap: 2px;

    .expirationContainer {
        background-color: #ddd;
        position: relative;
        max-width: calc(100vw - 55px);

        &::after {
            content: '>';
            position: absolute;
            right: 10px;
            top: 2px;
            transform: rotate(90deg);
        }

        &.open::after {
            transform: rotate(-90deg);
            right: 13px;
        }

        .expirationDate {
            cursor: pointer;
            display: grid;
            grid-gap: 3px;
            grid-template-columns: 1fr 1fr 1fr;
            height: 16px;
            overflow: hidden;
            padding: 3px 10px;
            transition: height .5s;

            div {
                text-align: center;
            }
        }

        &.open {
            .expirationDate {
                height: 36px;
            }
        }

        .optionChains {
            background-color: #fff;
            border-left: 5px solid #ddd;
            border-right: 5px solid #ddd;
            display: grid;
            grid-template-columns: 1fr 70px 1fr;
            grid-template-rows: 25px calc(100% - 25px);
            height: 0px;
            overflow: hidden;
            transition: height .5s;

            .chainTitles {
                background-color: #ededed;
            }

            .chainStrike {
                background-color: #ddd;
                padding: 3px 5px;
                text-align: center;
            }

            .chainTitles, .chainCalls, .chainStrikes, .chainPuts {
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .chainCall, .chainPut {
                cursor: pointer;
                // display: grid;
                // grid-template-columns: 70px 70px 70px 70px 70px 100px 70px 70px 70px 60px 60px 70px 60px 60px;
                white-space: nowrap;

                &.oddRow {
                    background-color: #f7f7f7;
                    .chainValue {
                        background-color: #f7f7f7;
                    }
                }

                &.inTheMoney {
                    background-color: #dce3ff;
                    .chainValue {
                        background-color: #dce3ff;
                    }

                    &.oddRow {
                        background-color: #ccd7f9;
                        .chainValue {
                            background-color: #ccd7f9;
                        }
                    }
                }

                &.selected {
                    background-color: #effa71 !important;
                    .chainValue {
                        // background-color: #9df0ff;
                        background-color: #effa71 !important;
                    }
                }

                .chainValue {
                    display: inline-block;
                    padding: 3px 0px;
                    text-align: center;
                }

                &:not(.chainTitles) {
                    .bid { color: #149d14; }
                    .ask { color: #c20909; }
                }
            }
        }

        &.open {
            .optionChains {
                border-bottom: 5px solid #ddd;
                height: 400px;
                // overflow: scroll;
            }
        }
    }
}
