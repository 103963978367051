.trades {

    .tradesHeader {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 10px;

        .lastUpdatedContainer {
            text-align: right;
        }
    }

    .filterContainer {
        margin-bottom: 10px;

        .filterAccountSelect {
            background-color: #fff;
            border-radius: 10px;
            display: inline-block;
            height: 31px;
            margin: 0px 10px;
            width: 200px;
        }
    }

    .tradesTypeHeader {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .winningTest .tableCell {
        background-color: rgba(231, 255, 231) !important;
    }

    .losingTest .tableCell {
        background-color: rgb(255 231 231) !important;
    }
}