.logsOverlay {
    background-color: #222;
    color: #fff;
    font-family: 'Menlo', 'Courier New', monospace;
    font-size: 12px;
    position: relative;

    .logsMenuBar {
        left: 0px;
        height: 15px;
        padding: 5px 10px;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 2;
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        grid-gap: 10px;

        .logsDragHandle {
            cursor: ns-resize;
        }

        .restartButton, .fullScreenButton {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .logsScrollContainer {
        bottom: 0px;
        left: 0px;
        overflow: auto;
        padding: 5px 10px;
        position: absolute;
        right: 0px;
        top: 25px;
        z-index: 1;

        .logsContainer {
            .logItem {
                white-space: pre-wrap;

                .time {
                    color: yellow;
                }
            }
        }
    }
}