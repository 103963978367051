.loadingOverlay {
    background-color: rgba(150, 150, 150, 0.3);
    bottom: 0px;
    left: 0px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: 0px;
    z-index: 100;

    .loadingIcon {
        background-color: #fff;
        font-size: 24px;
        left: 50%;
        padding: 5px 10px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.savedData {

    .savedDataHeader {
        display: grid;
        grid-template-columns: auto;
        margin-bottom: 10px;
    }

    .savedDataContainer {
        background-color: #fff;
        display: grid;
        font-size: 14px;
        grid-template-columns: 300px 200px 200px 200px 1fr 100px;
        grid-gap: 10px;
        margin-bottom: 15px;
        padding: 8px 10px 10px;
        position: relative;

        & > .myInput {
            margin: 0px;
        }

        .optionsContainer {
            grid-column: span 6;
            // min-height: 100px;
            max-width: calc(100vw - 55px);

            .getDataButtonContainer {
                display: grid;
                grid-template-columns: 1fr 300px 1fr;

                .getDataButton {
                    margin: 10px 0px;
                }
            }
        }
    }

    &.isSmall {
        .savedDataContainer {
            grid-template-columns: 1fr;

            // .myInput {
            //     margin-bottom: 10px;
            // }

            .optionsContainer {
                grid-column: span 1;
            }
        }
    }

    .addDataViewButtonContainer {
        background-color: #fff;
        padding: 10px calc(50% - 150px);

        .addDataViewButton {
            margin: 0px;
            width: 300px;
        }
    }
}
