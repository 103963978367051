.tests {

    .testsHeader {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 10px;

        .lastUpdatedContainer {
            text-align: right;
        }
    }

    .testsTypeHeader {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .createNewButtonContainer {
        margin: auto;
        width: 250px;
    }

    .ranTestsTableContainer {
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 14px 15px;

        .winningTest .tableCell {
            background-color: rgba(231, 255, 231) !important;
        }

        .losingTest .tableCell {
            background-color: rgb(255 231 231) !important;
        }
    }

    .runningTestContainer {
        background-color: #fff;
        border-radius: 15px;
        // display: grid;
        font-size: 12px;
        // grid-template-columns: 175px 160px 160px 150px 200px;
        grid-gap: 10px 20px;
        height: 75px;
        margin-bottom: 10px;
        padding: 10px 10px 12px;
        position: relative;
        transition: height .5s;

        &.smallRunningTestContainer {
            cursor: pointer;
            grid-template-columns: calc(100% - 30px);
            grid-gap: 10px;
            height: 105px;
            overflow: hidden;

            &.testExpanded {
                height: 330px;
            }
        }

        &.winningTest {
            background-color: rgba(0, 255, 0, 0.1);
        }

        &.losingTest {
            background-color: rgba(255, 0, 0, 0.1);
        }

        .runningTestInfoContainer {
            .runningTestInfo {
                margin-bottom: 2px;

                div {
                    display: inline-block;

                    &:last-child {
                        float: right;
                    }
                }
            }
        }

        .testActionButton {
            cursor: pointer;
            height: 15px;
            // line-height: 62px;
            position: absolute;
            right: 10px;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
        }

        .runningTestProgressBarContainer {
            background-color: #eee;
            border-radius: 5px;
            bottom: 10px;
            height: 10px;
            left: 10px;
            overflow: hidden;
            position: absolute;
            width: calc(100% - 20px);

            .runningTestProgressBar {
                background-color: #05adfb;
                bottom: 0px;
                left: 0px;
                position: absolute;
                top: 0px;
                transition: width 1s;
                width: 0%;
            }
        }
    }
}
