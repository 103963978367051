.actions {

    .actionsHeader {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 10px;

        .lastUpdatedContainer {
            text-align: right;
        }
    }

    .actionContainer {
        background-color: #fff;
        display: grid;
        font-size: 12px;
        grid-template-columns: 80px auto 300px;
        height: 16px;
        padding: 3px 6px;

        div:not(.actionLabel) {
            text-align: center;
        }
    }
}