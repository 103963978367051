.editBotContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    height: 100%;

    & > div {
        height: 415px;
    }

    &.smallDialog {
        grid-template-columns: 1fr;

        & > div {
            height: auto;
        }
    }
}