.botSettingsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 15px;
    height: 100%;
    overflow: auto;

    .inputContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;

        // .myInput {
        //     // margin-bottom: 0px;
        // }

        &.threeInputs {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &.isMobile {
        .inputContainer {
            grid-template-columns: 1fr;
        }
    }
}
